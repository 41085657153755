<template>
  <div class="container-fluid mt-3">
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label class="control-label">Access Code</label>
          <input class="form-control" v-model="model.accessCode"  type="text" disabled/>
        </div>
        <div class="form-group">
          <label class="control-label">Key</label>
          <input class="form-control" v-model="model.keyCode"  type="text" disabled/>
        </div>

        <div class="row">
          <div class="col-6">
            <label class="control-label">Show Logout Button {{logoutVisible }}</label><br/>
            <toggle v-model="logoutVisible" color="green" on-text="On" off-text="Off"></toggle>
          </div>

          <div class="col-6">
            <label class="control-label">Show Data on Device {{showData }}</label><br/>
            <toggle v-model="showData" color="green" on-text="On" off-text="Off"></toggle>
          </div>
        </div>
        <div class="form-group">
          <label class="control-label">User Survey</label>
          <select class="form-control" v-model="model.displaySurvey" @change="isDirty=true">
            <option value="0">No Survey</option>
            <option value="1">Questions on 5,12,17,and 24</option>
          </select>
        </div>

      </div>
      <div class="col-6">
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label class="control-label">Threshold</label>
              <select class="form-control" v-model="model.threshold" @change="isDirty=true">
                <option value="0">Auto ({{ model.calculatedThreshold}})</option>
                <option value="1">110</option>
                <option value="2">105</option>
                <option value="3">100</option>
                <option value="4">95</option>
                <option value="5">90</option>
                <option value="6">85</option>
                <option value="7">80</option>
                <option value="8">75</option>
                <option value="9">70</option>
                <option value="10">65</option>
                <option value="11">60</option>
                <option value="12">55</option>
                <option value="13">50</option>
                <option value="14">45</option>
                <option value="15">40</option>
                <option value="16">35</option>
                <option value="17">30</option>
              </select>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="control-label">Frequency</label>
              <select class="form-control" v-model="model.frequency" @change="isDirty=true">
                <option value="0">5 minutes</option>
                 <option value="1">4 minutes</option>
                 <option value="2">3 minutes</option>
                 <option value="3">2 minutes</option>
                 <option value="4">1 minute</option>
              </select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label class="control-label">Pattern</label>
              <select class="form-control" v-model="model.pattern" @change="isDirty=true">
                <option value="0">Default</option>
                <option value="1">Custom</option>
              </select>
            </div>
          </div>
        </div>

        <div v-show="model.pattern==1">
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label class="control-label">Haptic One</label>
                <select class="form-control" v-model="model.hapticL1" @change="isDirty=true">
                  <option value="0">Click</option>
                  <option value="1">Direction Down</option>
                  <option value="2">Direction Up</option>
                  <option value="3">Failure</option>
                  <option value="4">Notification</option>
                  <option value="5">Retry</option>
                  <option value="6">Start</option>
                  <option value="7">Stop</option>
                  <option value="8">Success</option>
                </select>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label class="control-label">Repeat One</label>
                <select class="form-control" v-model="model.repeatL1" @change="isDirty=true">
                  <option value="0">1</option>
                  <option value="1">2</option>
                  <option value="2">3</option>
                  <option value="3">4</option>
                  <option value="4">5</option>
                  <option value="5">6</option>
                  <option value="6">7</option>
                  <option value="7">8</option>
                  <option value="8">9</option>
                  <option value="9">10</option>
                </select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label class="control-label">Haptic two</label>
                <select class="form-control" v-model="model.hapticL2" @change="isDirty=true">
                  <option value="0">Click</option>
                  <option value="1">Direction Down</option>
                  <option value="2">Direction Up</option>
                  <option value="3">Failure</option>
                  <option value="4">Notification</option>
                  <option value="5">Retry</option>
                  <option value="6">Start</option>
                  <option value="7">Stop</option>
                  <option value="8">Success</option>
                </select>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label class="control-label">Repeat Two</label>
                <select class="form-control" v-model="model.repeatL2" @change="isDirty=true">
                  <option value="0">1</option>
                  <option value="1">2</option>
                  <option value="2">3</option>
                  <option value="3">4</option>
                  <option value="4">5</option>
                  <option value="5">6</option>
                  <option value="6">7</option>
                  <option value="7">8</option>
                  <option value="8">9</option>
                  <option value="9">10</option>
                </select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label class="control-label">Haptic Three</label>
                <select class="form-control" v-model="model.hapticL3" @change="isDirty=true">
                  <option value="0">Click</option>
                  <option value="1">Direction Down</option>
                  <option value="2">Direction Up</option>
                  <option value="3">Failure</option>
                  <option value="4">Notification</option>
                  <option value="5">Retry</option>
                  <option value="6">Start</option>
                  <option value="7">Stop</option>
                  <option value="8">Success</option>
                </select>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label class="control-label">Repeat Three</label>
                <select class="form-control" v-model="model.repeatL3" @change="isDirty=true">
                  <option value="0">1</option>
                  <option value="1">2</option>
                  <option value="2">3</option>
                  <option value="3">4</option>
                  <option value="4">5</option>
                  <option value="5">6</option>
                  <option value="6">7</option>
                  <option value="7">8</option>
                  <option value="8">9</option>
                  <option value="9">10</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    <div class="text-center mb-5 mt-2">
      <button v-show="nc.ctx.deviceLevel==2" class="btn nw-btn-success" :disabled="isDirty==false" @click="save()">Save</button>
    </div>
  </div>
</template>

<script>
import NetClient from '@/util/netclient.js'
import Toggle from '@/components/Toggle'

export default {
  components: { Toggle },
  mounted () {
      this.loadPatient()
  },

  watch: {
    'logoutVisible' (n,o) {
      if(n != o) this.isDirty = true
    },

    'showData' (n,o) {
      if(n != o) this.isDirty = true
    }
  },

  data () {
    return {
      nc: NetClient,
      isDirty: false,
      logoutVisible: false,
      showData: false,
      model: {
    		oid: -1
	     }
    }
  },
  methods: {
    loadPatient () {
      if(NetClient.ctx.deviceLevel > 0)
      {
        NetClient.doRequest('rest/patient', { action: 'patient', fieldType: 'device'}).then(msg => {
          if(typeof msg.data != 'undefined') this.model = msg.data

          this.logoutVisible = this.model.logoutButtonVisible == 0 ? false : true
          this.showData = this.model.showData == 0 ? false : true
          this.isDirty = false
        })
      }
    },
    save () {
      if(NetClient.ctx.deviceLevel == 2)
      {
        this.model.logoutButtonVisible = this.logoutVisible ? 1 : 0
        this.model.showData = this.showData ? 1 : 0

        NetClient.doRequest('rest/patient', { action: 'patient', fieldType: 'device', patient: this.model}).then(msg => {
          if(typeof msg.data != 'undefined') this.model = msg.data
          if('OK' == msg.retval) this.isDirty = false
        })
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
