<template>
  <div class="container-fluid mt-3">
    <div v-show="activePage=='demo'">
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label class="control-label">Patient ID</label>
            <input class="form-control" v-model="model.participantID"  type="text" disabled/>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label class="control-label">Access Code</label>
            <input class="form-control" v-model="model.accessCode"  type="text" disabled/>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label class="control-label">Initials</label>
            <input class="form-control" v-model="model.initials"  type="text" @keyup="isDirty=true"/>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label class="control-label">Date of Birth (MM/DD/YYYY)</label>
            <input class="form-control" v-model="model.dob"  type="text" @keyup="isDirty=true" placeholder="MM/DD/YYYY"/>
          </div>
        </div>
      </div>

      <div class="row  mb-5 mt-2">
        <div class="col-7 text-right">
          <button v-show="nc.ctx.demographicsLevel == 2" class="btn nw-btn-success" :disabled="isDirty==false" @click="save()">Save</button>
        </div>
        <div class="col-5 text-right" v-show="nc.ctx.adminRole==true || nc.ctx.suRole == true">
          <button class="btn nw-btn-outline-info" @click="activePage='archive'">Archive</button>
        </div>
      </div>
    </div>

    <div class="text-center" v-show="activePage=='archive'">
      <h3>Archive Patient ID {{ model.participantID}}</h3>
      <div class="row border-top border-bottom">
        <div class="col-6 offset-3 text-justify h5 pt-3 pb-3">
          You have selected to archive this patient record. This will result in a new blank record being created for Patient ID <span class="font-weight-bold">{{ model.participantID}}</span> and
          the exiting patient ID being renamed to <span class="font-weight-bold">{{ model.participantID}}.{{ model.oid}}</span>. This existing patient will no longer be able to send data to the server.
          If you wish to proceed press the "Archive!" buttton. If not, then "Cancel".
        </div>
      </div>
      <div class="text-center mt-4">
          <button class="btn nw-btn-outline-info" @click="activePage='demo'">Cancel</button>
          <button class="btn nw-btn-success ml-5" @click="archive()">Archive!</button>
      </div>
    </div>

    <Errors :resp="resp" :retval="retval"></Errors>
  </div>
</template>

<script>
import NetClient from '@/util/netclient.js'
import Errors from '@/components/Errors'

export default {
  components: {Errors},
  mounted () {
      this.loadPatient()
  },

  data () {
    return {
      nc: NetClient,
      isDirty: false,
      retval: 'OK',
      resp: [],
      activePage: 'demo',
      model: {
    		oid: -1,
    		participantID: '',
    		initials: ''
	     }
    }
  },
  methods: {
    loadPatient () {
      this.retval = 'OK'
      this.resp = []
      if(NetClient.ctx.demographicsLevel > 0)
      {
        NetClient.doRequest('rest/patient', { action: 'patient', fieldType: 'demographics'}).then(msg => {
          if(typeof msg.data != 'undefined') this.model = msg.data
          if(typeof msg.retval != 'undefined') this.retval = msg.retval
          if(typeof msg.resp != 'undefined') this.resp = msg.resp
        })
      }
    },
    archive()
    {
      this.retval = 'OK'
      this.resp = []
      if(NetClient.ctx.demographicsLevel == 2)
      {
        NetClient.doRequest('rest/patient', { action: 'resetParticipantID'}).then(msg => {
          if(typeof msg.data.patient != 'undefined') this.model = msg.data.patient
          if('OK' == msg.retval)
          {
            this.isDirty = false
            this.activePage = 'demo'
          }

          if(typeof msg.retval != 'undefined') this.retval = msg.retval
          if(typeof msg.resp != 'undefined') this.resp = msg.resp
        })
      }
    },
    save () {
      this.retval = 'OK'
      this.resp = []
      if(NetClient.ctx.demographicsLevel == 2)
      {
        NetClient.doRequest('rest/patient', { action: 'patient', fieldType: 'demographics', patient: this.model}).then(msg => {
          if(typeof msg.data != 'undefined') this.model = msg.data
          if('OK' == msg.retval) this.isDirty = false
          if(typeof msg.retval != 'undefined') this.retval = msg.retval
          if(typeof msg.resp != 'undefined') this.resp = msg.resp
        })
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
