<template>
  <div class="container-fluid mt-3">
    <div class="row">
      <div class="col-3">
        <label class="control-label">Study Participant</label><br/>
        <toggle v-model="studyParticipant" color="green" on-text="On" off-text="Off"></toggle>
      </div>
      <div class="col-4">
        <div class="form-group">
          <label class="control-label">Study Code</label>
          <input class="form-control" v-model="model.studyCode"  type="text" @keyup="isDirty=true"/>
        </div>
      </div>
      <div class="col-4">
        <label class="control-label">Placebo</label><br/>
        <toggle v-model="placebo" color="green" on-text="On" off-text="Off"></toggle>
      </div>
    </div>
    <div class="text-center mb-5 mt-2">
      <button v-show="nc.ctx.clinicalLevel==2" class="btn nw-btn-success" :disabled="isDirty==false" @click="save()">Save</button>
    </div>
  </div>
</template>

<script>
import NetClient from '@/util/netclient.js'
import Toggle from '@/components/Toggle'

export default {
  components: { Toggle },
  mounted () {
      this.loadPatient()
  },

  watch: {
    'placebo' (n,o) {
      if(n != o) this.isDirty = true
    },

    'studyParticipant' (n,o) {
      if(n != o) this.isDirty = true
    }

  },

  data () {
    return {
      nc: NetClient,
      isDirty: false,
      placebo: false,
      studyParticipant: false,
      model: {
    		oid: -1,
        studyCode: ''
	     }
    }
  },
  methods: {
    loadPatient () {
      if(NetClient.ctx.demographicsLevel > 0)
      {
        NetClient.doRequest('rest/patient', { action: 'patient', fieldType: 'clinical'}).then(msg => {
          if(typeof msg.data != 'undefined') this.model = msg.data
          this.placebo = this.model.placebo == 0 ? false : true
          this.studyParticipant = this.model.studyFlag == 0 ? false : true
          this.isDirty = false
        })
      }
    },
    save () {
      this.model.placebo = this.placebo ? 1 : 0
      this.model.studyFlag = this.studyParticipant ? 1 : 0
      if(NetClient.ctx.demographicsLevel == 2)
      {
        NetClient.doRequest('rest/patient', { action: 'patient', fieldType: 'clinical', patient: this.model}).then(msg => {
          if(typeof msg.data != 'undefined') this.model = msg.data
          if('OK' == msg.retval) this.isDirty = false
        })
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
