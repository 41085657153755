<template>
  <div class="container-fluid text-left">
    <div class="card nw-card">
      <div class="card-header nw-card-header">
        <div class="row">
          <div class="col-xs-12 col-sm-6 text-xs-center text-sm-left"><h4>Patient - {{ nc.ctx.patientName }}</h4></div>
          <div class="col-xs-12 col-sm-6 text-xs-center text-sm-right"><h4>{{ nc.ctx.patientID }}</h4></div>
        </div>
      </div>
      <div class="card-body text-left">
        <ul class="nav nav-tabs">
          <li class="nav-item" v-show="nc.ctx.demographicsLevel > 0">
            <a class="nav-link text-dark" :class="activePage=='profile' ? 'active' : ''"  @click="activePage='profile'" href="#">Profile</a>
          </li>
          <li class="nav-item" v-show="nc.ctx.deviceLevel > 0">
            <a class="nav-link text-dark" :class="activePage=='device' ? 'active' : ''"  @click="activePage='device'" href="#">Device</a>
          </li>
          <li class="nav-item" v-show="nc.ctx.clinicalLevel > 0">
            <a class="nav-link text-dark" :class="activePage=='clinical' ? 'active' : ''" @click="activePage='clinical'"  href="#">Clinical</a>
          </li>
          <li class="nav-item" v-show="nc.ctx.adminRole == true || nc.ctx.suRole == true">
            <a class="nav-link text-dark" :class="activePage=='permissions' ? 'active' : ''" @click="activePage='permissions'" href="#">Permissions</a>
          </li>
        </ul>

        <Demographics v-show="activePage=='profile'"></Demographics>
        <Device v-show="activePage=='device'"></Device>
        <Clinical v-show="activePage=='clinical'"></Clinical>
        <Permissions v-show="activePage=='permissions'"></Permissions>

      </div>
    </div>
  </div>
</template>

<script>
import Toggle from '@/components/Toggle'
import Demographics from './Demographics'
import Device from './Device'
import Clinical from './Clinical'
import Permissions from './Permissions'
import NetClient from '@/util/netclient.js'

export default {
  components: { Toggle,Demographics,Device,Clinical,Permissions },
  data () {
    return {
      activePage: 'profile',
      nc: NetClient
    }
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
